@media (min-width: 1024px) {
  .hide-desktop {
    display: none;
  }
  .container-form-contact {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 60px;
  }
  .content-inputs {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 20px;
  }
  .app-logo img {
    width: 100px;
  }
  .item-menu-header {
    width: 30px;  
  }
}

@media (max-width: 1023px) {
  .container-inputs {
    margin-bottom: 3rem;
  }
  .item-menu-header {
    width: 26px;
  }
  .app-logo img {
    width: 70px;
  }
  .container {
    padding: 1rem;
  }
  .categories.active {
    display: inline;
  }
  .categories {
    display: none;
    position: fixed;
    width: 100%;
    height: 100%;
    background: #fff;
    flex-direction: column;
  }
  .container-form-contact {
    grid-row-gap: 40px;
  }
  .hide-mobile {
    display: none;
  }
}