/* Utilidades */
.flex {
    display: flex;
}

.grid {
    display: grid;
}

.block {
    display: block;
}

.flex-1 {
    flex: 1 1 0%;
}

.items-center {
    align-items: center;
}

.justify-center {
    justify-content: center;
}

.justify-between {
    justify-content: space-between;
}

.justify-end {
    justify-content: flex-end;
}

.px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
}

.py-2  {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.p-2 {
    padding: 0.5rem;
}

.p-4 {
    padding: 1rem;
}

.mx-4 {
    margin: 1rem;
}
.mt-2 {
    margin-top: 0.5rem
}
.mt-4 {
    margin-top: 1rem;
}	
.cursor-pointer {
    cursor: pointer;
}

.text-center {
    text-align: center;
}

.relative {
    position: relative;
}

.absolute {
    position: absolute;
}

.uppercase {
    text-transform: uppercase;
}

.container {
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
}

.w-full {
    width: 100%;
}