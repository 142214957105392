body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.menu-header {
  color: #2f2f2f;  
}

.menu-header span {
  margin-left: 10px;
}

.note-offers {
  transform: rotateZ(-90deg);    
  left: -40px;
  top: 0px;
  font-size: 14px;
}

.note-offers .note {  
  color: #fff;
}

.note-offers .note:nth-child(1) {
  background-color: #373534;
  transform: skewX(35deg);
  padding: 3px 20px;
}

.note-offers .note:nth-child(2) {
  background-color: #5f5d5d;
  padding: 3px 35px;
  transform: translateX(10px) skewX(-20deg);
}

.note-offers .note:nth-child(1) span {
  transform: skewX(-35deg);
}

.note-offers .note:nth-child(2) span {
  transform: skewX(20deg);
}

.category {
  color: #2f2f2f;
  border-bottom: 1px solid transparent;
}

.category:hover {
  border-color: #c83735;
}

.category:last-of-type {
  color: #c83735;
}

.divider {  
  height: 1px;
  background-color: #cccccc;
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.title-form {
  margin: 0;
  margin-bottom: 40px;
  font-weight: 500;
  color: #2c4772;
  font-size: 18px;
}

.content-inputs {    
  grid-row-gap: 20px;
}

.input-form {
  border: none;
  border-bottom: 1px solid #7d7d7d;
  padding: 10px;
  box-sizing: border-box;
}

.btn-send {
  margin-top: auto;
  margin-left: auto;
  background-color: #2c4772;
  color: #fff;
  padding: 8px 30px;
  border: none;
  cursor: pointer;
}

.form-contact {
  display: flex;
  flex-direction: column;
}

select:focus, input:focus {
  outline: none;
}

select {
  background: url(/images/icons/down-arrow.svg) no-repeat right;
  -webkit-appearance: none;
  background-position-x: 98%;
  background-size: 13px;
}

.menu-movil {
  background-image: url(/images/icons/list.svg);
  width: 30px;
  height: 30px;
}

.menu-movil.open {
  background-image: url(/images/icons/close.svg);
}

.count-cart {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 12px;
  margin-top: 2px;
}

.note-offers-mobile {
  font-size: 12px;
}

.note-offers-mobile .note {
  padding: 5px 10px;
  color: #fff;
}

.note-offers-mobile .note::after {
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  z-index: -1;
}

.note-offers-mobile .note:nth-child(1) {  
  background-color: #373534;
  text-align: right;
  border-bottom-right-radius: 10px;
}

.note-offers-mobile .note:nth-child(1)::after {  
  background-color: #5f5d5d;  
  bottom: 0;
  right: 0;
}

.note-offers-mobile .note:nth-child(2) {
  background-color: #5f5d5d;
  text-align: left;
  border-top-left-radius: 10px;
}

.note-offers-mobile .note:nth-child(2)::after {  
  background-color: #373534;
  top: 0;
  left: 0;
}

.empty-fields {
  background-color: #c83735;
  color: #fff;
  text-align: center;
  padding: 5px;
  margin-top: 30px;
}